import { Text } from "@ds/react";
import * as S from "./DetailsSection.style";
import { useContext } from "react";
import OdontoContext from "context/OdontoContext";

const DetailsSection = () => {
  const { percentageZoom } = useContext(OdontoContext);
  return (
    <>
      <S.Container className="copyright" zoom={percentageZoom}>
        <Text variant="caption-02" as="p">
          Plano Sigma IF (comercializado como Plano SIGMA) - Registro ANS nº
          466.300/12-4 <br /> Odonto Empresas Convênios Dentários Ltda ANS - n°
          310981 CRO-SP - EPAO: 8859 <br />
          RT: BRUNA SANTORO DE BRUYN - CRO/SP: 103610
        </Text>
        <Text variant="caption-02" as="p">
          A Odonto Empresas (CNPJ 40.223.893/0001-59) faz parte do Grupo CNP 
          Seguradora que reúne também empresas de Seguros (CNPJ 92.751.213/0001-73), 
          Consórcio (CNPJ 05.349.595/0001-09) e Capitalização (CNPJ 01.599.296/0001-71).
        </Text>
        <Text variant="caption-02" as="p">
          © Copyright 2022 Plano Odonto Sigma
        </Text>
      </S.Container>
    </>
  );
};

export default DetailsSection;
