import React from "react";
import * as S from "./AtualizacoesPolitica.style";

function AtualizacoesPolitica() {
  return (
    <S.Gapping>
      <S.Container>
        <b>Atualizações da Política de Privacidade da Odonto Empresas</b> <br />
        <S.PStyled>
          Esta é a 3ª versão da Política de Privacidade, publicada em
          13/06/2024.
        </S.PStyled>
        <S.PStyled>
          Essa política poderá ser atualizada, caso haja necessidade. Mas, pode
          deixar, a gente informa quando houver alguma atualização.{" "}
        </S.PStyled>
        <S.PStyled>
          Caso você tenha alguma dúvida, a gente tem um responsável pelo
          tratamento dos seus dados pessoais, junto com uma equipe exclusiva
          para cuidar desse tema tão importante. Se preferir, você também pode
          fazer as suas solicitações relacionadas a seus direitos diretamente
          pelo e-mail a seguir:
        </S.PStyled>
        <S.PStyled>
          Enio Roboredo Sanches <br />
          <S.LinkStyled href="mailto:privacidade@odontoempresas.com.br">
            privacidade@odontoempresas.com.br
          </S.LinkStyled>
          <br />
        </S.PStyled>
        <S.PStyled>
          Você também pode falar com a gente pelo Serviço de Atendimento ao
          Consumidor 24h, que está preparado para atender suas dúvidas e
          solicitações.
        </S.PStyled>
        <S.PStyled>
          <S.LinkStyled href="tel:0800 272 2000"> 0800 272 2000</S.LinkStyled>{" "}
          (Ligação gratuita de qualquer local do Brasil)
        </S.PStyled>
        <S.PStyled>
          Além disso, há no Brasil a Autoridade Nacional de Proteção de Dados
          Pessoais (ANPD), agência pública criada com o propósito de zelar pela
          proteção, controle e transparência dos dados pessoais, fiscalizando
          como as empresas estão respeitando a LGPD. Caso você queira, pode
          entrar em contato com a ANPD pelo site{" "}
          <S.LinkStyled href="https://www.gov.br/anpd" target="_blank">
            www.gov.br/anpd
          </S.LinkStyled>
          .
        </S.PStyled>
        <S.PStyled>
          Além de cuidar do seu sorriso, a gente tem o compromisso de melhorar a
          sua segurança e de cuidar da privacidade e proteção de seus dados
          pessoais. Fique à vontade para falar com a gente sempre que quiser!
        </S.PStyled>
      </S.Container>
    </S.Gapping>
  );
}

export default AtualizacoesPolitica;
